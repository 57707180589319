import axios, { AxiosResponse } from "axios";
import {
  GetUserResponse,
  LoginResponse,
  MeasurementResponse,
  PasswordInitResponse,
  PostResponse
} from "../domain/Response";
import { Training, TrainingDetail, TrainingList } from "../domain/Training";
import noImage from "../assets/no-image.png"
import { Measurement } from "../domain/Measurement";

const API_URL = process.env.REACT_APP_API_URL
const client = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  xsrfHeaderName: 'XSRF-TOKEN'
});

client.defaults.baseURL = API_URL
client.defaults.withCredentials = true
client.defaults.headers.common = {
  "X-Requested-With": "XMLHttpRequest",
}
const csrfUrl = "/sanctum/csrf-cookie"

export const fetchLogin = async (email: string, password: string) => {
  return await client.get(csrfUrl).then(async (): Promise<LoginResponse> => {
    try {
      const res = await client.post('/api/login', { email, password })
      return { result: res.data.result, isPasswordInit: res.data.isPasswordInit, }
    } catch (e: any) {
      throw e.response.data
    }
  })
}

export const fetchLogout = async () => {
  return await client.get(csrfUrl).then(async (): Promise<PostResponse> => {
    try {
      const res = await client.post<PostResponse>('/api/logout',)
      return res.data
    } catch (e: any) {
      throw e.response.data
    }
  }).catch((e) => {
    throw e
  })
}

export const fetchResetPasswordRequest = async (email: string) => {
  return await client.get(csrfUrl).then(async (): Promise<PostResponse> => {
    try {
      const res = await client.post<PostResponse>('/api/forget-password', { email })
      return res.data
    } catch (e: any) {
      throw e.response.data
    }
  })
}

export const fetchPostResetPassword = async (email: string, password: string, token: string) => {
  return await client.get(csrfUrl).then(async (): Promise<PostResponse> => {
    try {
      const res = await client.post<PostResponse>('/api/reset-password', { email, password, token })
      return res.data
    } catch (e: any) {
      throw e.response.data
    }
  })
}

export const fetchInitPassword = async (password: string): Promise<PasswordInitResponse> => {
  return await client.post('/api/user/init-password', { password },).then((res: AxiosResponse<PasswordInitResponse>) => {
    return res.data
  }).catch((e) => {
    throw e
  })
}

export const fetchGetUser = async (): Promise<GetUserResponse> => {
  return await client.get('/api/user', {}).then((res) => {
    return { data: res.data }
  }).catch((e) => {
    throw e
  })
}

export const fetchGetTrainingMonth = async (): Promise<string[]> => {
  return await client.get<Array<string>>('/api/training-month').then((res) => {
    // return { data: res.data }
    return res.data
  }).catch((e) => {
    throw e
  })
}

export const fetchTrainingImage = async (imagePath: string): Promise<string> => {
  if (imagePath === "") return noImage
  return await client.get<string>('/api/training-image', {
    params: {
      imgPath: imagePath
    },
    // responseType: "arraybuffer",
  }).then((res) => {
    // return new Buffer(res.data).toString('base64')
    return res.data.toString()
  }).catch((e) => {
    throw e
  })
}

export const fetchTrainings = async (targetYearMonth: string): Promise<TrainingList> => {
  return await client.get<TrainingList>('/api/trainings', {
    params: {
      target_YM: targetYearMonth
    }
  }).then((res) => {
    return res.data
  }).catch((e) => {
    throw e
  })
}

export const fetchTrainingDetail = async (trainingId: string): Promise<TrainingDetail> => {
  return await client.get<TrainingDetail>('/api/trainings/' + trainingId).then((res) => {
    return res.data
  }).catch((e) => {
    throw e
  })
}

export const fetchMesurements = async (): Promise<Measurement> => {
  return await client.get<Measurement>('/api/measurements').then((res) => {
    return res.data
  }).catch((e) => {
    throw e
  })
}