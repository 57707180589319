import { StyledDiv } from "../Home";
import { useEffect, useState } from "react";
import { fetchMesurements } from "../../service/api";
import { Measurement, MeasurementItem } from "../../domain/Measurement";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import loginBg2 from "../../assets/login_BG2.jpg";
import { Link, useNavigate } from "react-router-dom";
import bodyIcon1 from "../../assets/body_icon1.png";
import bodyIcon2 from "../../assets/body_icon2.png";
import body from "../../assets/body.png";
import newIcon from "../../assets/new_icon.png";
import { Bar, Chart } from "react-chartjs-2";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  registerables
} from "chart.js";
import { MeasurementCard } from "../../component/feature/MeasurementCard";
import { Loading } from "../../component/common/Loading";
import { persister } from "../../index";
import dayjs from "dayjs";
import { DateFormat } from "../../domain/DateFormat";

ChartJS.register(
  ...registerables,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
)


interface MainView {
  measurementItems?: MeasurementItem[],
  mainViewItemName: string
}

export const MeasurementPage = () => {
  const isPc = useMediaQuery("(min-width:768px)")
  const [ mainViewList, setMainViewList ] = useState<MainView>()
  const [ measurementList, setMeasurementList ] = useState<Measurement>()
  const [ dataset, setDataset ] = useState<any>()
  const [ loading, setLoading ] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {

    async function get() {
      setLoading(true)
      try {
        const res = await fetchMesurements()
        setMeasurementList(res)
        if (res.armLeftList.length > 0) {
          changeMain(res.armLeftList, "腕周り(左)")
          changeLine(res.armLeftList)
        } else if (res.armRightList.length > 0) {
          changeMain(res.armRightList, "腕周り(右)")
          changeLine(res.armRightList)
        } else if (res.chestList.length > 0) {
          changeMain(res.chestList, "胸囲")
          changeLine(res.chestList)
        } else if (res.waistList.length > 0) {
          changeMain(res.waistList, "腹囲")
          changeLine(res.waistList)
        } else if (res.hipList.length > 0) {
          changeMain(res.hipList, "お尻周り")
          changeLine(res.hipList)
        } else if (res.thighLeftList.length > 0) {
          changeMain(res.thighLeftList, "もも周り(左)")
          changeLine(res.thighLeftList)
        } else if (res.thighRightList.length > 0) {
          changeMain(res.thighRightList, "もも周り(右)")
          changeLine(res.thighRightList)
        } else {
          changeMain([], "")
        }
      } catch (e: any) {
        if (e.response && e.response.status === 401) {
          persister.purge()
          alert("セッション切れたため、ログアウトします")
          navigate("/login")
        }
        console.log(e)
        alert(e.message())
      } finally {
        setLoading(false)
      }
    }

    get()
  }, [])

  const options = {
    scaleOverride: true,
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
      title: {
        display: false
      },
    },
    scales: {
      x: {
        display: true,
        ticks: {
          padding: isPc ? 10 : 10,

        },
        gridLines: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      y: {
        display: true,
        grid: {
          display: false,
          offset: true,
        },
        gridLines: {
          display: false,
        },
        beginAtZero: true,
        ticks: {
          display: false,
        }
      },

    },
    layout: {
      padding: { top: 3, }
    }
  }


  const changeMain = (measurementItems: MeasurementItem[], measurementName: string) => {
    setLoading(true)
    setMainViewList({
      measurementItems: measurementItems.sort((a, b) => {
        if (dayjs(a.measurementDate).isBefore(dayjs(b.measurementDate))) return -1
        return 1
      }), mainViewItemName: measurementName
    })
    setLoading(false)
  }

  const changeLine = (measurementItems: MeasurementItem[]) => {
    setLoading(true)
    const labels = measurementItems.map((view) => {
      return dayjs(view.measurementDate).format(DateFormat.YYYY_MM_DD)
    })
    console.log(labels)
    if (typeof labels === "undefined") return;
    const sizes = measurementItems.map((view) => {
      return view.size
    })
    if (typeof sizes === "undefined") return;
    setDataset({
      labels,
      datasets: [ {
        label: "",
        type: "line" as const,
        data: sizes,
        color: "#ccc",
        pointBackgroundColor: sizes.map((value, index) => {
          if (index === sizes.length - 1 && !isPc) {
            return "#DE776B"
          }
          return "#9D9168"
        }),
        pointBorderColor: sizes.map((value, index) => {
          if (index === sizes.length - 1 && !isPc) {
            return "#DE776B"
          }
          return "#9D9168"
        }),
        borderColor: "#DFE0E4",
        borderWidth: 3,
      }, {
        label: "",
        type: "bar" as const,
        data: sizes,
        color: "#ccc",
        backgroundColor: sizes.map((value, index) => {
          if (index === sizes.length - 1 && !isPc) {
            return "#DE776B"
          }
          return "#9D9168"
        }),
        borderColor: sizes.map((value, index) => {
          if (index === sizes.length - 1 && !isPc) {
            return "#DE776B"
          }
          return "#9D9168"
        }),
        // borderColor: "#DFE0E4",
        borderWidth: 0.1,
        barPercentage: 0.1,
        categoryPercentage: 0.1,
        barThickness: isPc ? 5 : 2,
      }
      ]
    })
    setLoading(false)
  }

  if (mainViewList === undefined) return (<Loading/>)
  if (loading || mainViewList.measurementItems === undefined) {
    return (<Loading/>)
  }
  return (
    <StyledDiv>
      <Box width={ "100%" } textAlign={ "center" } bgcolor={ "#464646" } sx={ {
        textAlign: "center",
        paddingY: 3
      } }>
        <Typography
          variant={ "h2" }
          sx={ { fontSize: isPc ? 24 : 20, fontWeight: "bold", fontFamily: "Arvo" } }>部位別測定</Typography>
      </Box>
      <Box
        sx={ {
          background: "#fff",
          width: "100%",
          paddingY: 3,
        } }
      >
        <Box sx={ {
          width: "100%",
          marginX: "auto"
        } }>
          <Box sx={ {
            display: "flex",
            width: "100%",
            justifyContent: "center"
          } }
          >
            <Box sx={ {
              position: "relative",
              width: isPc ? "70%" : "100%",
              maxWidth: isPc ? 400 : 185,
              minHeight: isPc ? 600 : 270,
              justifyContent: "center",
              textAlign: isPc ? "center" : "center",
            } }>
              <Box sx={ { position: "absolute", left: isPc ? 0 : 0, width: "100%" } }>
                <Box sx={ {
                  width: isPc ? 220 : 105,
                  minWidth: isPc ? 220 : 105,
                  maxWidth: isPc ? 220 : 105,
                  marginX: "auto",
                  position: "relative",
                } }>
                  <img
                    src={ body }
                    style={ {
                      objectFit: "cover",
                      width: "100%",
                    } }/>
                  <Button sx={ {
                    position: "absolute",
                    top: isPc ? 100 : 40,
                    left: isPc ? -25 : -28,
                    backgroundColor: mainViewList.mainViewItemName === "腕周り(左)" ? '#A39670' : "#000",
                    paddingX: 0.5,
                    paddingY: 0.5,
                    color: "#fff",
                    fontSize: isPc ? 16 : 10,
                    opacity: 0.7,
                    minWidth: isPc ? 100 : 60,
                    ":hover": {
                      backgroundColor: mainViewList.mainViewItemName === "腕周り(左)" ? '#A39670' : "#000",
                      opacity: 0.5
                    }
                  } } onClick={ async () => {
                    await changeMain(measurementList?.armLeftList!!, "腕周り(左)")
                    await changeLine(measurementList?.armLeftList!!)
                  } } disabled={ measurementList?.armLeftList.length === 0 }
                  >
                    <Box sx={ {
                      position: 'absolute',
                      top: '100%',
                      left: '90%',
                      transform: 'translateX(-50%)',
                      width: 0,
                      height: 0,
                      borderTop: mainViewList.mainViewItemName === "腕周り(左)" ? '6px solid #A39670' : '4px solid #000',
                      borderRight: '2px solid transparent',
                      borderLeft: '3px solid transparent',
                    } }></Box>
                    腕周り(左)
                  </Button>
                  <Button sx={ {
                    position: "absolute",
                    top: isPc ? 100 : 40,
                    left: isPc ? 150 : 70,
                    backgroundColor: mainViewList.mainViewItemName === "腕周り(右)" ? '#A39670' : "#000",
                    paddingX: 0.5,
                    paddingY: 0.5,
                    color: "#fff",
                    fontSize: isPc ? 16 : 10,
                    opacity: 0.7,
                    minWidth: isPc ? 100 : 60,
                    ":hover": {
                      backgroundColor: mainViewList.mainViewItemName === "腕周り(右)" ? '#A39670' : "#000",
                      opacity: 0.5,
                    }
                  } } onClick={ async () => {
                    await changeMain(measurementList?.armRightList!!, "腕周り(右)")
                    await changeLine(measurementList?.armRightList!!)
                  } } disabled={ measurementList?.armRightList.length === 0 }>
                    <Box sx={ {
                      position: 'absolute',
                      top: '100%',
                      left: '10%',
                      transform: 'translateX(-50%)',
                      width: 0,
                      height: 0,
                      borderTop: mainViewList.mainViewItemName === "腕周り(右)" ? '6px solid #A39670' : '6px solid #000',
                      borderRight: '3px solid transparent',
                      borderLeft: '2px solid transparent',
                    } }></Box>
                    腕周り(右)
                  </Button>
                  <Button sx={ {
                    position: "absolute",
                    top: isPc ? 160 : 75,
                    left: isPc ? 120 : 60,
                    backgroundColor: mainViewList.mainViewItemName === "胸囲" ? '#A39670' : "#000",
                    paddingX: 0.5,
                    paddingY: 0.25,
                    color: "#fff",
                    fontSize: isPc ? 16 : 10,
                    opacity: 0.7,
                    minWidth: 5,
                    ":hover": {
                      backgroundColor: mainViewList.mainViewItemName === "胸囲" ? '#A39670' : "#000",
                      opacity: 0.5
                    }
                  } } onClick={ async () => {
                    await changeMain(measurementList?.chestList!!, "胸囲")
                    await changeLine(measurementList?.chestList!!)
                  } } disabled={ measurementList?.chestList.length === 0 }>
                    <Box sx={ {
                      position: 'absolute',
                      top: '50%',
                      right: '100%',
                      transform: 'translateY(-50%)',
                      width: 0,
                      height: 0,
                      borderRight: mainViewList.mainViewItemName === "胸囲" ? '6px solid #A39670' : '6px solid #000',
                      borderTop: '2px solid transparent',
                      borderBottom: '2px solid transparent',
                    } }></Box>
                    胸囲
                  </Button>
                  <Button sx={ {
                    position: "absolute",
                    top: isPc ? 200 : 100,
                    left: isPc ? 50 : 10,
                    backgroundColor: mainViewList.mainViewItemName === "腹囲" ? '#A39670' : "#000",
                    paddingX: 0.5,
                    paddingY: 0.25,
                    color: "#fff",
                    fontSize: isPc ? 16 : 10,
                    opacity: 0.7,
                    minWidth: 5,
                    ":hover": {
                      backgroundColor: mainViewList.mainViewItemName === "腹囲" ? '#A39670' : "#000",
                      opacity: 0.5,
                    }
                  } } onClick={ async () => {
                    await changeMain(measurementList?.waistList!!, "腹囲")
                    await changeLine(measurementList?.waistList!!)
                  } } disabled={ measurementList?.waistList.length === 0 }>
                    <Box sx={ {
                      position: 'absolute',
                      top: '50%',
                      left: '100%',
                      transform: 'translateY(-50%)',
                      width: 0,
                      height: 0,
                      borderLeft: mainViewList.mainViewItemName === "腹囲" ? '6px solid #A39670' : '6px solid #000',
                      borderTop: '2px solid transparent',
                      borderBottom: '3px solid transparent',
                    } }></Box>
                    腹囲
                  </Button>
                  <Button sx={ {
                    position: "absolute",
                    top: isPc ? 250 : 130,
                    left: isPc ? 140 : 70,
                    backgroundColor: mainViewList.mainViewItemName === "お尻周り" ? '#A39670' : "#000",
                    paddingX: 0.5,
                    paddingY: 0.25,
                    color: "#fff",
                    fontSize: isPc ? 16 : 10,
                    opacity: 0.7,
                    minWidth: isPc ? 80 : 50,
                    ":hover": {
                      backgroundColor: mainViewList.mainViewItemName === "お尻周り" ? '#A39670' : "#000",
                      opacity: 0.5,
                    }
                  } } onClick={ async () => {
                    await changeMain(measurementList?.hipList!!, "お尻周り")
                    await changeLine(measurementList?.hipList!!)
                  } } disabled={ measurementList?.hipList.length === 0 }>
                    <Box sx={ {
                      position: 'absolute',
                      top: '50%',
                      right: '100%',
                      transform: 'translateY(-50%)',
                      width: 0,
                      height: 0,
                      borderTop: '3px solid transparent',
                      borderBottom: '2px solid transparent',
                      borderRight: mainViewList.mainViewItemName === "お尻周り" ? '6px solid #A39670' : '6px solid #000',
                    } }></Box>
                    お尻周り
                  </Button>
                  <Button sx={ {
                    position: "absolute",
                    top: isPc ? 340 : 170,
                    left: isPc ? -10 : -20,
                    backgroundColor: mainViewList.mainViewItemName === "もも周り(左)" ? '#A39670' : "#000",
                    paddingX: 0.5,
                    paddingY: 0.25,
                    color: "#fff",
                    fontSize: isPc ? 16 : 10,
                    opacity: 0.7,
                    minWidth: isPc ? 110 : 5,
                    ":hover": {
                      backgroundColor: mainViewList.mainViewItemName === "もも周り(左)" ? '#A39670' : "#000",
                      opacity: 0.5,
                    }
                  } } onClick={ async () => {
                    await changeMain(measurementList?.thighLeftList!!, "もも周り(左)")
                    await changeLine(measurementList?.thighLeftList!!)
                  } } disabled={ measurementList?.thighLeftList.length === 0 }>
                    <Box sx={ {
                      position: 'absolute',
                      bottom: '100%',
                      left: '90%',
                      transform: 'translateX(-50%)',
                      width: 0,
                      height: 0,
                      borderBottom: mainViewList.mainViewItemName === "もも周り(左)" ? '6px solid #A39670' : '6px solid #000',
                      borderRight: '2px solid transparent',
                      borderLeft: '3px solid transparent',
                    } }></Box>
                    もも周り(左)
                  </Button>
                  <Button sx={ {
                    position: "absolute",
                    top: isPc ? 340 : 170,
                    left: isPc ? 120 : 55,
                    backgroundColor: mainViewList.mainViewItemName === "もも周り(右)" ? '#A39670' : "#000",
                    paddingX: 0.5,
                    paddingY: 0.25,
                    color: "#fff",
                    fontSize: isPc ? 16 : 10,
                    opacity: 0.7,
                    minWidth: isPc ? 110 : 70,
                    ":hover": {
                      backgroundColor: mainViewList.mainViewItemName === "もも周り(右)" ? '#A39670' : "#000",
                      opacity: 0.5,
                    }
                  } } onClick={ async () => {
                    await changeMain(measurementList?.thighRightList!!, "もも周り(右)")
                    await changeLine(measurementList?.thighRightList!!)
                  } } disabled={ measurementList?.thighRightList.length === 0 }>
                    <Box sx={ {
                      position: 'absolute',
                      bottom: '100%',
                      left: '10%',
                      transform: 'translateX(-50%)',
                      width: 0,
                      height: 0,
                      borderBottom: mainViewList.mainViewItemName === "もも周り(右)" ? '6px solid #A39670' : '6px solid #000',
                      borderRight: '3px solid transparent',
                      borderLeft: '2px solid transparent',
                    } }></Box>
                    もも周り(右)
                  </Button>
                </Box>

              </Box>
            </Box>
            { isPc ? (
              <Box sx={ { marginTop: 5, minWidth: 500, maxWidth: 650, width: "100%", } }>
                <Box sx={ { display: "flex", justifyContent: "center", width: "90%", marginX: "auto" } }
                >
                  <MeasurementCard
                    measurementItems={ measurementList?.armLeftList }
                    isMain={ mainViewList.mainViewItemName === "腕周り(左)" }
                    name={ "腕周り(左)" }
                  />
                  <MeasurementCard
                    measurementItems={ measurementList?.armRightList }
                    isMain={ mainViewList.mainViewItemName === "腕周り(右)" }
                    name={ "腕周り(右)" }
                  />
                </Box>
                <Box sx={ { marginTop: 1, display: "flex", justifyContent: "center", width: "90%", marginX: "auto" } }>
                  <MeasurementCard
                    measurementItems={ measurementList?.chestList }
                    isMain={ mainViewList.mainViewItemName === "胸囲" }
                    name={ "胸囲" }
                  />
                  <MeasurementCard
                    measurementItems={ measurementList?.waistList }
                    isMain={ mainViewList.mainViewItemName === "腹囲" }
                    name={ "腹囲" }
                  />
                </Box>
                <Box sx={ { marginTop: 1, display: "flex", justifyContent: "center", width: "90%", marginX: "auto" } }>
                  <MeasurementCard
                    measurementItems={ measurementList?.hipList }
                    isMain={ mainViewList.mainViewItemName === "お尻周り" }
                    name={ "お尻周り" }
                  />
                  <Box width={ "100%" } sx={ { marginRight: 2, padding: 1, minWidth: 220 } }></Box>
                </Box>
                <Box sx={ { marginTop: 1, display: "flex", justifyContent: "center", width: "90%", marginX: "auto" } }>
                  <MeasurementCard
                    measurementItems={ measurementList?.thighLeftList }
                    isMain={ mainViewList.mainViewItemName === "もも周り(左)" }
                    name={ "もも周り(左)" }
                  />
                  <MeasurementCard
                    measurementItems={ measurementList?.thighRightList }
                    isMain={ mainViewList.mainViewItemName === "もも周り(右)" }
                    name={ "もも周り(右)" }
                  />
                </Box>
              </Box>
            ) : (<Box sx={ {
              marginLeft: 0,
              boxShadow: 3,
              textAlign: "center",
              color: "#000",
              width: 150,
              height: "90%",
              marginY: "auto",
              paddingY: 2,
            } }>
              <Typography component={ "p" } fontWeight={ "bold" }
                          fontFamily={ "Arvo" }>{ mainViewList.mainViewItemName }</Typography>
              <Box sx={ {
                display: "flex",
                justifyContent: "center",
                position: "relative",
                alignItems: "end"
              } }>
                <Box sx={ {
                  marginX: 1,
                } }>
                  <img src={ newIcon } style={ { objectFit: "cover" } } width={ 40 }/>
                </Box>
                <Box sx={ { marginTop: 1, textAlign: "left", marginX: 1, } }>
                  <Typography
                    component={ "span" }
                    sx={ { fontSize: 12 } }
                  >
                    { mainViewList?.measurementItems?.length > 0 ? dayjs(mainViewList.measurementItems[mainViewList.measurementItems.length - 1].measurementDate).format(DateFormat.YYYY_MM_DD) : "" }
                  </Typography>
                  <Typography
                    component={ "p" }
                    sx={ { fontSize: 22, fontWeight: "bold" } }
                  >
                    { mainViewList?.measurementItems?.length > 0 ? parseInt(mainViewList.measurementItems[mainViewList.measurementItems.length - 1].size.toString()) : "" }
                    <span style={ { fontWeight: "normal", fontSize: 16 } }>cm</span>
                  </Typography>
                </Box>
              </Box>

              <Box sx={ { width: "90%", marginX: "auto", color: "#B5BCBD" } }>
                <hr/>
              </Box>
              <Box sx={ {
                display: "flex",
                justifyContent: "center",
                position: "relative",
                alignItems: "end"
              } }>
                <Box sx={ {
                  marginX: 1,
                  width: 40,
                } }>
                </Box>
                <Box sx={ { textAlign: "left", marginX: 1, } }>
                  <Typography
                    component={ "span" }
                    sx={ { fontSize: 12 } }
                  >
                    { mainViewList?.measurementItems?.length > 0 ? dayjs(mainViewList.measurementItems[0].measurementDate).format(DateFormat.YYYY_MM_DD) : "" }
                  </Typography>
                  <Typography
                    component={ "p" }
                    sx={ { fontSize: 22, fontWeight: "bold" } }
                  >
                    { mainViewList?.measurementItems?.length > 0 ? parseInt(mainViewList.measurementItems[0].size.toString()): "" }
                    <span style={ { fontWeight: "normal", fontSize: 16 } }>cm</span>
                  </Typography>
                </Box>
              </Box>
            </Box>) }
          </Box>
        </Box>
        <Box sx={ {
          width: isPc ? 600 : "90%",
          marginX: "auto",
          marginTop: 0
        } }>
          { isPc ? (
            <Box sx={ {
              background: "#000",
              display: "flex",
              alignItems: "center",
              borderRadius: 3,
              width: "100%",
              marginBottom: 10,
              visibility: dataset !== undefined ? "visible": "hidden"
            } }>
              <Box sx={ {
                color: "#fff",
                marginX: 1,
                width: 130,
                textAlign: "center"
              } }>
                { mainViewList.mainViewItemName }
              </Box>
              <Box sx={ {
                marginY: 1,
                marginRight: 1,
                background: "#fff",
                maxWidth: 550,
                width: "100%",
              } }
              >
                { !loading && dataset !== undefined && (<Box>
                  <Chart type={ "bar" } data={ dataset } options={ options } height={ 100 }></Chart>
                  <Box sx={ {
                    width: "90%",
                    display: "flex",
                    marginX: "auto",
                    justifyContent: "space-between",
                    marginBottom: 2,
                  } }>
                    { mainViewList.measurementItems?.map((item) => {
                      return (
                        <Box sx={ {
                          color: "#000",
                          fontSize: 16,
                          textAlign: "center",
                          width: 120,
                          lineHeight: 0.8,
                          marginTop: 0.5,
                        } }>{ parseInt(item.size.toString()) }<br/>
                          <span style={ { fontSize: 12 } }>cm</span>
                        </Box>
                      )
                    }) }
                  </Box>
                </Box>) }
              </Box>
            </Box>
          ) : (
            <>
              { !loading && dataset !== undefined && (
                <Chart type={ "bar" } data={ dataset } options={ options }></Chart>) }
            </>
          ) }
        </Box>
        { !isPc && (
          <Box sx={ {
            width: "90%",
            display: "flex",
            marginX: "auto",
            justifyContent: "space-between"
          } }>
            { mainViewList.measurementItems?.map((item) => {
              return (
                <Box sx={ {
                  color: "#000",
                  fontSize: 14,
                  textAlign: "center",
                  width: 120,
                  lineHeight: 0.8,
                  marginTop: 0.5,
                } }>{ parseInt(item.size.toString()) }<br/>
                  <span style={ { fontSize: 12 } }>cm</span>
                </Box>
              )
            }) }
          </Box>
        ) }
        { !isPc && (
          <Box sx={ {
            width: "90%",
            marginX: "auto",
            display: "flex",
            justifyContent: "center",
            marginTop: 3,
            textAlign: "center",
          } }>
            <Button sx={ {
              backgroundColor: mainViewList.mainViewItemName === "腕周り(左)" ? '#A39670' : "#424242",
              borderRadius: 1,
              fontSize: 12,
              padding: 0,
              paddingY: 2,
              paddingX: 1,
              width: 80,
              maxWidth: 85,
              marginX: 0.5,
              color: "#fff",
            } } onClick={ async () => {
              await changeMain(measurementList?.armLeftList!!, "腕周り(左)")
              await changeLine(measurementList?.armLeftList!!)
            } } disabled={ measurementList?.armLeftList.length === 0 }>腕周り(左)</Button>
            <Button sx={ {
              backgroundColor: mainViewList.mainViewItemName === "腕周り(右)" ? '#A39670' : "#424242",
              borderRadius: 1,
              fontSize: 12,
              padding: 0,
              paddingY: 2,
              paddingX: 1,
              width: 80,
              maxWidth: 85,
              color: "#fff",
            } } onClick={ async () => {
              await changeMain(measurementList?.armRightList!!, "腕周り(右)")
              await changeLine(measurementList?.armRightList!!)
            } } disabled={ measurementList?.armRightList.length === 0 }>腕周り(右)</Button>
            <Button sx={ {
              backgroundColor: mainViewList.mainViewItemName === "胸囲" ? '#A39670' : "#424242",
              borderRadius: 1,
              fontSize: 12,
              padding: 0,
              paddingY: 2,
              paddingX: 1,
              width: 80,
              maxWidth: 85,
              marginX: 0.5,
              color: "#fff",
            } } onClick={ async () => {
              await changeMain(measurementList?.chestList!!, "胸囲")
              await changeLine(measurementList?.chestList!!)
            } } disabled={ measurementList?.chestList.length === 0 }>胸囲</Button>
            <Button sx={ {
              backgroundColor: mainViewList.mainViewItemName === "腹囲" ? '#A39670' : "#424242",
              borderRadius: 1,
              fontSize: 12,
              padding: 0,
              paddingY: 2,
              paddingX: 1,
              width: 80,
              maxWidth: 85,
              marginRight: 0.5,
              color: "#fff",
            } } onClick={ async () => {
              await changeMain(measurementList?.waistList!!, "腹囲")
              await changeLine(measurementList?.waistList!!)
            } } disabled={ measurementList?.waistList.length === 0 }>腹囲</Button>
          </Box>
        ) }
        { !isPc && (
          <Box sx={ {
            width: "90%",
            marginX: "auto",
            display: "flex",
            justifyContent: "center",
            marginTop: 1,
            textAlign: "center",
          } }>
            <Button sx={ {
              backgroundColor: mainViewList.mainViewItemName === "お尻周り" ? '#A39670' : "#424242",
              borderRadius: 1,
              fontSize: 12,
              padding: 0,
              paddingY: 2,
              paddingX: 1,
              width: 80,
              maxWidth: 85,
              marginX: 0.5,
              color: "#fff",
            } } onClick={ async () => {
              await changeMain(measurementList?.hipList!!, "お尻周り")
              await changeLine(measurementList?.hipList!!)
            } } disabled={ measurementList?.hipList.length === 0 }>お尻周り</Button>
            <Button sx={ {
              backgroundColor: mainViewList.mainViewItemName === "もも周り(左)" ? '#A39670' : "#424242",
              borderRadius: 1,
              fontSize: 12,
              padding: 0,
              paddingY: 2,
              paddingX: 1,
              width: 80,
              maxWidth: 85,
              color: "#fff",
            } } onClick={ async () => {
              await changeMain(measurementList?.thighLeftList!!, "もも周り(左)")
              await changeLine(measurementList?.thighLeftList!!)
            } } disabled={ measurementList?.thighLeftList.length === 0 }>もも周り(左)</Button>
            <Button sx={ {
              backgroundColor: mainViewList.mainViewItemName === "もも周り(右)" ? '#A39670' : "#424242",
              borderRadius: 1,
              fontSize: 12,
              padding: 0,
              paddingY: 2,
              paddingX: 1,
              width: 80,
              maxWidth: 85,
              marginX: 0.5,
              color: "#fff",
            } } onClick={ async () => {
              await changeMain(measurementList?.thighRightList!!, "もも周り(右)")
              await changeLine(measurementList?.thighRightList!!)
            } } disabled={ measurementList?.thighRightList.length === 0 }>もも周り(右)</Button>
            <Button sx={ {
              backgroundColor: "#fff",
              borderRadius: 1,
              fontSize: 14,
              padding: 0,
              paddingY: 2,
              paddingX: 1,
              width: 80,
              maxWidth: 85,
              marginRight: 0.5,
              color: "#fff",
              // visibility: "hidden"
            } }></Button>
          </Box>
        ) }
      </Box>
      { isPc && (
        <Box
          sx={ { background: "#D3D6DC", height: 80 } }
        ></Box>
      ) }
      {
        isPc ? (
          <Box
            sx={ {
              width: "100%",
              backgroundImage: `url(${ loginBg2 })`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              objectFit: "cover",
              paddingY: 10,
              display: "flex",
              justifyContent: "center",
              textAlign: "center"
            } }
          >
            <Link to={ "/training" } style={ {
              color: isPc ? "#fff" : "#A39760",
              textDecoration: "none",
              background: isPc ? "#A39670" : "#353535",
              padding: 10,
              width: 360 ,
              height: 240,
              boxSizing: "border-box",
              marginLeft: 10,
              fontWeight: "bold"
            } }>
              <Box
                sx={ {
                  marginTop: isPc ? 5 : 1,
                  alignItems: "center",
                  marginBottom: 1,
                } }
              >
                <img src={ bodyIcon1 } width={ 100 }/>
              </Box>
              過去のボディを見る
            </Link>
          </Box>
        ) : (
          <Box sx={ {
            width: "90%",
            marginX: "auto",
            marginY: 3,
            display: "flex",
            textAlign: "center",
            justifyContent: "center"
          } }>
            <Link to={ "/training" } style={ {
              color: "#A39760",
              textDecoration: "none",
              background: isPc ? "#A39670" : "#353535",
              padding: 10,
              width: 180,
              height: 100,
              borderRadius: 10,
              boxSizing: "border-box",
              fontWeight: "bold"
            } }>
              <Box
                sx={ {
                  marginTop: isPc ? 5 : 1,
                  alignItems: "center",
                  marginBottom: 1,
                } }
              >
                { (
                  <img src={ bodyIcon2 } width={ 50 }/>) }
              </Box>
              過去のボディを見る
            </Link>
          </Box>
        )
      }
      {
        !isPc && (
          <Box
            sx={ {
              width: "100%",
              backgroundImage: `url(${ loginBg2 })`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              objectFit: "cover",
              paddingY: 10,
              display: "flex",
              justifyContent: "center",
              textAlign: "center"
            } }
          >
          </Box>
        )
      }
    </StyledDiv>
  )
}