import AppHeader from "../../component/common/AppHeader";
import {
  Alert,
  Box,
  Button,
  FormHelperText,
  InputLabel,
  OutlinedInput, Snackbar,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
import styled from "styled-components";
import AppFooter from "../../component/common/AppFooter";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import loginBg from "../../assets/login_BG2.jpg"
import { KeyboardArrowRight } from "@mui/icons-material";
import * as yup from "yup"
import { ValidationMessage } from "../../domain/ValidationMessage";
import { Sentence } from "../../component/common/Sentence";
import { useState } from "react";
import { fetchResetPasswordRequest } from "../../service/api";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getLoggedIn } from "../../features/auth/authSlice";
import { Loading } from "../../component/common/Loading";


const Main = styled.main`
  padding-top: 50px;
  background: #535353;
  color: #000;
`

interface PasswordResetForm {
  email: string
}

const schemaRules = yup.object({
  email: yup.string()
    .required(ValidationMessage.required)
    .email(ValidationMessage.email)
})

const PasswordForget = () => {
  const isPC = useMediaQuery("(min-width:768px)")
  const isLoggedIn = useSelector(getLoggedIn)
  const navigate = useNavigate()
  const [ isSend, setIsSend ] = useState(false)
  const [ isError, setIsError ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState("")
  const [loading, setLoading] = useState(false)
  if (isLoggedIn) {
    navigate("/")
  }
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm<PasswordResetForm>({})
  const onSubmit: SubmitHandler<PasswordResetForm> = async (data: PasswordResetForm) => {
    setLoading(true)
    setIsSend(true)
    try {
      const res = await fetchResetPasswordRequest(data.email)
      if (res.result) {
        setIsSend(true)
      }
    } catch (e: any) {
      setIsError(true)
      setErrorMessage(e.message)
    } finally {
      setLoading(false)
    }

  }

  if (loading) {
    return (<Loading/>)
  }
  return (
    <>
      <AppHeader isLoggedIn={ false }/>
      <Main>
        <Box sx={ {
          backgroundImage: `url(${ loginBg })`,
          paddingY: 2,
          backgroundSize: "cover",
          backgroundPositionY: "center"
        } }>
          <Typography component={ "h2" } variant={ "h4" } sx={ {
            marginY: 2,
            textAlign: "center",
            fontSize: { xs: 20, md: 32 },
            fontWeight: "bold",
            color: "#fff"

          } }>パスワード再発行／忘れた方</Typography>
        </Box>
        <Box sx={ {
          marginX: "auto",
          width: "90%",
          textAlign: "center",
          paddingY: 3,
          background: "#EDEDED"
        } }>
          <Box sx={ { width: "90%", marginX: "auto" } }>
            <Sentence>ご登録されたアカウントのメールアドレスを</Sentence>
            <Sentence>ご入力ください。</Sentence>
            <Sentence>パスワード再発行URLをお送りいたします。</Sentence>
          </Box>
          { !isSend ? (<Stack
            component={ "form" }
            noValidate
            onSubmit={ handleSubmit(onSubmit) }
            sx={ { width: "90%", marginX: "auto", marginY: 2 } }
            visibility={ !isSend ? "visible" : "hidden" }
          >
            <Box marginY={ 2 } width={ "100%" } textAlign={ isPC ? "right" : "left" }
                 sx={ isPC ? { fontSize: 16 } : { fontSize: 14 } }>
              <InputLabel
                sx={ isPC ? { color: "#535353", display: "inline", fontSize: 16 } : { color: "#535353", fontSize: 14 } }
              >メールアドレス</InputLabel>
              <OutlinedInput
                { ...register('email') }
                type={ "email" }
                fullWidth={ !isPC }
                error={ "email" in errors }
                label={ "メールアドレス" }
                placeholder={ "メールアドレスを入力してください" }
                sx={ isPC ? {
                    width: 320, background: "#D3D7DE", marginX: 2, fontSize: 16, color: "#535353"
                  }
                  : {
                    background: "#D3D7DE", fontSize: 14, color: "#535353"
                  } }
                size={ "small" }
                required={ true }
                aria-invalid={ errors.email ? "true" : "false" }
              />
              <FormHelperText sx={ { color: "red", textAlign: "center" } }>{ errors.email?.message }</FormHelperText>
            </Box>
            <Button variant={ "contained" } type={ "submit" } sx={ {
              background: "#353535",
              color: '#A39760',
              width: 200,
              marginX: "auto",
              paddingY: 1,
              fontSize: 20,
              marginTop: 3,
              fontFamily: "Arvo",
              fontWeight: "bold",
              ":hover": {
                background: "#353535",
                opacity: 0.9
              }
            } }
            >
              SEND <KeyboardArrowRight sx={ { fontSize: 20, fontWeight: 100 } }/>
            </Button>
            <Snackbar
              open={ isError }
              autoHideDuration={ 6000 }
              onClose={ () => setIsError(false) }
            >
              <Alert
                severity={ "error" }
                onClose={ () => setIsError(false) }
                sx={ { width: '100%' } }
              >{ errorMessage }</Alert>
            </Snackbar>
          </Stack>) : (
            <Box sx={ {
              marginTop: 2,
            } }>
              <Typography
                visibility={ isSend ? "visible" : "hidden" }>
                入力されたメールアドレスにパスワード再発行URLを送付いたしました。
              </Typography>
              <Link to={ "/login" } style={ {
                display: "block",
                background: "#353535",
                color: '#A39760',
                width: 200,
                marginLeft: "auto",
                marginRight: "auto",
                fontSize: 20,
                marginTop: 20,
                textAlign: "center",
                textDecoration: "none",
                fontFamily: "Arvo",
              } }>
                LOGIN画面に戻る
              </Link>
            </Box>) }
        </Box>
      </Main>
      <AppFooter/>
    </>
  )
}
export default PasswordForget