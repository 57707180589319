import { Button, Icon, useMediaQuery } from "@mui/material";

type Props = {
  marginX: number
}
export const ReservationButton = ({marginX}: Props) => {
  const isPC = useMediaQuery("(min-width:768px)")
  return (
    <Button
      sx={ {
        border: "1px solid #f77060",
        background: "#f77060",
        color: "#535353",
        fontWeight: "bold",
        marginX: marginX,
        paddingRight: 1,
        marginY: 1,
        fontSize: isPC? 16 : 12,
      } }
    >
      <Icon
        sx={ {
          borderRadius: 50,
          background: "#000",
          fontSize: isPC? 16: 12,
          marginX: 0.5
        } }
      >check</Icon>
      <label>予約する</label>
    </Button>
  )
}