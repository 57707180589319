import { Box, Button, Card } from "@mui/material";
import { MeasurementItem } from "../../domain/Measurement";

type Props = {
  measurementItems?: MeasurementItem[]
  isMain: boolean,
  name: string,
}

export const MeasurementCard = (props: Props) => {
  return (
    <Box sx={ {
      display: "flex",
      alignItems: "center",
      padding: 1,
      background: props.isMain ? "#000" : "#ccc",
      marginRight: 2,
      width: "100%",
      minWidth: 220,
      borderRadius: 3,
      justifyContent: "space-around"
    } }>
      <Box
        sx={ {
          color: props.isMain ? "#fff" : "#000",
          marginX: 0.5,
          fontSize: 16,
          minWidth: 70,
          maxWidth: 150
        } }>{ props.name }</Box>
      <Box sx={ {
        color: props.isMain ? "#fff" : "#000", width: 150
      } }>
        <Box sx={ { marginBottom: 0.5, marginTop: 1, background: "#fff", padding: 0.5, fontSize: 12, color: "#555" } }>
          <span style={ {
            marginRight: 5,
            color: "#555",
            fontSize: 12
          } }>{ props.measurementItems !== undefined && props.measurementItems.length > 0 && (props.measurementItems[0].measurementDate) }</span>
          <span style={ {
            fontSize: 16,
            color: "#000",
            fontWeight: "bold",
          } }>{ props.measurementItems !== undefined && props.measurementItems.length > 0 ? props.measurementItems[0].size : "未測定です" }</span>
          <span
            style={ { marginLeft: 0.5 } }>{ props.measurementItems !== undefined && props.measurementItems?.length > 0 ? "cm" : "" }</span>
        </Box>
        <Box sx={ { marginBottom: 1, background: "#fff", padding: 0.5, color: "#555", fontSize: 12 } }>
          <span style={ {
            marginRight: 5,
            fontSize: 12
          } }>{ props.measurementItems !== undefined && props.measurementItems.length > 0 && (props.measurementItems[props.measurementItems.length - 1].measurementDate) }</span>
          <span style={ {
            fontSize: 16,
            color: "#000",
            fontWeight: "bold"
          } }>{ props.measurementItems !== undefined && props.measurementItems.length > 0 ? props.measurementItems[props.measurementItems.length - 1].size : "未測定です" }</span>
          <span
            style={ { marginLeft: 1 } }>{ props.measurementItems !== undefined && props.measurementItems?.length > 0 ? "cm" : "" }</span>
        </Box>
      </Box>
    </Box>
  )
}