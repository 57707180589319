import { Button } from "@mui/material";
import homeIcon from "../../assets/home_icon.png"
import { Link } from "react-router-dom";

export const HomeButton = () => {
  const handleSubmit = () => {

  }
  return (
    <Link
      to={ "/" }
      style={ {
        marginRight: 0,
        marginLeft: 2,
        paddingRight: 1,
        color: "#fff",
        position: "absolute",
        left: 75,
        top: 15,
        textDecoration: "none"
      } }
    >
      <img src={ homeIcon } width={ 25 } style={ { objectFit: "cover" } }/>
    </Link>
  )
}