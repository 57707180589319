import {
  Alert,
  Box,
  Button,
  FormHelperText,
  InputLabel,
  OutlinedInput, Snackbar,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import loginBg from "../../assets/login_BG2.jpg";
import { KeyboardArrowRight } from "@mui/icons-material";
import styled from "styled-components";
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { fetchPostResetPassword } from "../../service/api";
import { Sentence } from "../../component/common/Sentence";
import { ValidationMessage } from "../../domain/ValidationMessage";
import { useState } from "react";
import AppFooter from "../../component/common/AppFooter";
import AppHeader from "../../component/common/AppHeader";
import { useSelector } from "react-redux";
import { getLoggedIn } from "../../features/auth/authSlice";
import { Loading } from "../../component/common/Loading";

const Main = styled.main`
  color: #000;
`

interface PasswordReSetForm {
  email: string,
  password: string,
  confirmPassword: string
}

export const PasswordReSet = () => {
  const [ searchParams ] = useSearchParams()
  const isLoggedIn = useSelector(getLoggedIn)
  const navigate = useNavigate()
  const token = searchParams.get("token")
  const isPC = useMediaQuery("(min-width:768px)")
  const [ isSend, setIsSend ] = useState(false)
  const [ isError, setIsError ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState("")
  const [loading, setLoading] = useState(false)
  const {
    register,
    formState: { errors },
    getValues,
    trigger,
    handleSubmit
  } = useForm<PasswordReSetForm>({
    defaultValues: {
      password: '',
      confirmPassword: '',
    }
  })

  if (isLoggedIn) {
    navigate("/")
  }

  const onSubmit: SubmitHandler<PasswordReSetForm> = async (data: PasswordReSetForm) => {
    setLoading(true)
    try {
      if (token == null) {
        await alert("不正なアクセスのため、ログインページに移動します。")
        navigate("/login")
        return
      }
      const res = await fetchPostResetPassword(data.email, data.password, token as string)
      if (res.result) {
        setIsSend(true)
        navigate("/")
      }

    } catch (e: any) {
      setIsError(true)
      setErrorMessage(e.message)
    } finally {
      setLoading(false)
    }
    setIsSend(true)
  }
  if (loading) {
    return (<Loading/>)
  }
  return (
    <>
      <AppHeader isLoggedIn={false}/>
      <Main>
        <Box sx={ {
          backgroundImage: `url(${ loginBg })`,
          paddingY: 2,
          backgroundSize: "cover",
          backgroundPositionY: "center"
        } }>
          <Typography component={ "h2" } variant={ "h4" } sx={ {
            marginY: 2,
            textAlign: "center",
            fontSize: { xs: 20, md: 32 },
            fontWeight: "bold",
            color: "#fff"

          } }>パスワード再設定</Typography>
        </Box>
        <Box sx={ {
          marginX: "auto",
          width: "90%",
          // width: {xs:"90%", sm: "80%", lg: "50%"},
          textAlign: "center",
          paddingY: 3,
          background: "#EDEDED"
        } }>
          <Box sx={ { width: "90%", marginX: "auto" } }>
            <Sentence>再設定パスワードを入力してください。</Sentence>
          </Box>
          { !isSend ? (<Stack
            component={ "form" }
            noValidate
            onSubmit={ handleSubmit(onSubmit) }
            sx={ { width: { xs: "90%", sm: "85%", md: "70%", lg: "50%" }, marginX: "auto", marginY: 2 } }
            visibility={ isSend ? "hidden" : "visible" }
          >
            <Box marginY={ 1 } width={ "100%" } textAlign={ isPC ? "right" : "left" }
                 sx={ isPC ? { fontSize: 16 } : { fontSize: 14 } }>
              <InputLabel
                sx={ isPC ? { color: "#535353", display: "inline", fontSize: 16 } : { color: "#535353", fontSize: 14 } }
              >ID（メールアドレス）</InputLabel>
              <OutlinedInput
                { ...register('email') }
                type={ "email" }
                fullWidth={ !isPC }
                error={ "email" in errors }
                label={ "ID(メールアドレス)" }
                placeholder={ "メールアドレスかIDを入力してください" }
                sx={ isPC ? {
                    width: 320, background: "#D3D7DE", marginX: 2, fontSize: 16, color: "#535353"
                  }
                  : {
                    background: "#D3D7DE", fontSize: 14, color: "#535353"
                  } }
                size={ "small" }
                required={ true }
                aria-invalid={ errors.email ? "true" : "false" }
              />
              <FormHelperText sx={ { color: "red", textAlign: "center" } }>{ errors.email?.message }</FormHelperText>
            </Box>
            <Box marginY={ 1 } width={ "100%" } textAlign={ isPC ? "right" : "left" } sx={ { fontSize: 12 } }>
              <label>パスワード入力</label>
              <OutlinedInput
                { ...register("password", {
                  required: ValidationMessage.required,
                  minLength: { value: 8, message: ValidationMessage.min },
                  onBlur: () => {
                    if (getValues("confirmPassword")) {
                      trigger("confirmPassword")
                    }
                  }
                }) }
                type={ "password" }
                fullWidth={ !isPC }
                error={ "password" in errors }
                label={ "パスワード" }
                placeholder={ "パスワードを入力してください" }
                sx={ isPC ? {
                    width: 400, background: "#D3D7DE", marginX: 2, fontSize: 16, border: "none", color: "#535353"
                  }
                  : {
                    background: "#D3D7DE", fontSize: 14, color: "#535353"
                  } }
                size={ "small" }
              />
              <FormHelperText sx={ { color: "red", textAlign: "center" } }>{ errors.password?.message }</FormHelperText>
            </Box>
            <Box marginY={ 1 } width={ "100%" } textAlign={ isPC ? "right" : "left" } sx={ { fontSize: 12 } }>
              <label>パスワード入力（確認）</label>
              <OutlinedInput
                { ...register("confirmPassword", {
                  required: ValidationMessage.required,
                  minLength: { value: 8, message: ValidationMessage.min },
                  validate: (value) => {
                    return (
                      value === getValues("password") || ValidationMessage.passwordNotEqual
                    )
                  }
                }) }
                type={ "password" }
                fullWidth={ !isPC }
                error={ "confirmPassword" in errors }
                label={ "パスワード（確認）" }
                placeholder={ "パスワードを入力してください" }
                sx={ isPC ? {
                    width: 400, background: "#D3D7DE", marginX: 2, fontSize: 16, border: "none", color: "#535353"
                  }
                  : {
                    background: "#D3D7DE", fontSize: 14, color: "#535353"
                  } }
                size={ "small" }
              />
              <FormHelperText
                sx={ { color: "red", textAlign: "center" } }>{ errors.confirmPassword?.message }</FormHelperText>
            </Box>
            <Button variant={ "contained" } type={ "submit" } sx={ {
              background: "#353535",
              color: '#A39760',
              width: 200,
              marginX: "auto",
              paddingY: 1,
              fontSize: 20,
              marginTop: 3,
              fontFamily: "Arvo",
              fontWeight: "bold",
              ":hover": {
                background: "#353535",
                opacity: 0.9
              }
            } }
            >
              SEND <KeyboardArrowRight sx={ { fontSize: 20, fontWeight: 100 } }/>
            </Button>
            <Snackbar
              open={ isError }
              autoHideDuration={ 6000 }
              onClose={ () => setIsError(false) }
            >
              <Alert
                severity={ "error" }
                onClose={ () => setIsError(false) }
                sx={ { width: '100%' } }
              >{ errorMessage }</Alert>
            </Snackbar>
          </Stack>) : (<Box>
            <Typography visibility={ isSend ? "visible" : "hidden" }>パスワード再設定が完了しました。</Typography>
            <Link to={ "/login" } style={ {
              display: "block",
              background: "#353535",
              color: '#A39760',
              width: 200,
              marginLeft: "auto",
              marginRight: "auto",
              fontSize: 20,
              marginTop: 20,
              textAlign: "center",
              textDecoration: "none",
              fontFamily: "Arvo",
            } }>LOGIN画面へ戻る</Link>
          </Box>) }
        </Box>
      </Main>
      <AppFooter/>
    </>

  )
}
