import { BrowserRouter, Route, Routes, Navigate, LoaderFunctionArgs } from "react-router-dom";
import Home from "../pages/Home";
import Login from "../pages/auth/Login";
import { Layout } from "./Layout";
import PasswordInit from "../pages/auth/PasswordInit";
import PasswordForget from "../pages/auth/PasswordForget";
import { PasswordReSet } from "../pages/auth/PasswordReSet";
import { useSelector } from "react-redux";
import { getLoggedIn } from "../features/auth/authSlice";
import { TrainingList } from "../pages/training/TrainingList";
import { Detail } from "../pages/training/Detail";
import { MeasurementPage } from "../pages/measurement/MeasurementPage";

const Router = () => {
  const isLoggedIn = useSelector(getLoggedIn)
  return (
    <BrowserRouter>
      <Routes>
        <Route element={ isLoggedIn ? <Layout/> : <Navigate to={ "/login" }/> }>
          <Route path={ "/" } element={ isLoggedIn ? <Home/> : <Navigate to={ "/login" }/> }></Route>
          <Route path={ "/password-init" } element={ <PasswordInit/> }></Route>
          <Route path={ "/measurement" } element={ <MeasurementPage/> }></Route>
          <Route path={ "/training" } element={ <TrainingList/> }>
          </Route>
          <Route path={ "/training/:trainingId" } element={ <Detail/> }></Route>
        </Route>
        <Route path={ "/login" } element={ <Login/> }></Route>
        <Route path={ "/password-forget" } element={ <PasswordForget/> }></Route>
        <Route path={ "/password-reset" } element={ <PasswordReSet/> }></Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Router