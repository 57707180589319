import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  Box, ImageList,
  ImageListItem, ImageListItemBar,
  MenuItem,
  Select,
  useMediaQuery
} from "@mui/material";
import loginBg2 from "../../assets/login_BG2.jpg"
import { StyledDiv } from "../Home";
import dayjs from "dayjs";
import { DateFormat } from "../../domain/DateFormat";
import { useEffect, useState } from "react";
import { fetchGetTrainingMonth, fetchTrainingImage, fetchTrainings } from "../../service/api";
import { Training } from "../../domain/Training";
import measurementIcon1 from "../../assets/measurement_icon1.png"
import measurementIcon2 from "../../assets/measurement_icon2.png"
import { Loading } from "../../component/common/Loading";
import { persister } from "../../index";

export interface TrainingTargetMonthForm {
  yearMonth: string,
}

export const TrainingList = () => {
  const isPc = useMediaQuery("(min-width:768px)")
  const [ searchParams ] = useSearchParams()
  const navigate = useNavigate()
  const paramYM = searchParams.get("targetYM") as string
  const [ yearMonthItems, setYearMonthItems ] = useState<string[]>([])
  const [ targetYearMonth, setTargetYearMonth ] = useState<string>()
  const [ trainingImages, setTrainingImages ] = useState<Training[]>([])
  const [ loading, setLoading ] = useState(false)

  useEffect(() => {
    setLoading(true)

    async function getTrainingYearMonth() {
      try {
        const yearMonths = await fetchGetTrainingMonth()
        if (yearMonths.length === 0) {
          return
        }
        setYearMonthItems(yearMonths)
        if (searchParams.has("targetYM")) {
          setTargetYearMonth(searchParams.get("targetYM") as string)
          await getTrainings(searchParams.get("targetYM") as string)
        } else {
          setTargetYearMonth(yearMonths[0])
          await getTrainings(yearMonths[0])
        }
      } catch (e: any) {
        if (e.response && e.response.status === 401) {
          alert("セッションタイムアウトのため、ログアウトします。")
          persister.purge()
          navigate("/login")
          return
        }
        alert(e)
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    getTrainingYearMonth()

  }, [])

  const getTrainings = (async (targetYM: string) => {
    setLoading(true)
    if (targetYM === "") {
      return Array<Training>()
    }
    const res = await fetchTrainings(targetYM)
    const trainingsWithImage = Promise.all(res.trainings.map(async (training) => {
      const image = await fetchTrainingImage(training.thumbnailUrl)
      return {
        trainingId: training.trainingId,
        trainingDate: training.trainingDate,
        thumbnailUrl: image
      }
    }))
    setTrainingImages(await trainingsWithImage)
    setLoading(false)
  })

  if (loading) {
    return (<Box>
      <Loading/>
    </Box>)
  }
  return (
    <StyledDiv onContextMenu={ (e) => {
      e.preventDefault()
      return false
    } }>
      <Box width={ "100%" } textAlign={ isPc ? "left" : "center" } bgcolor={ "#464646" }>
        <Select
          labelId={ "targetYearMonthLabel" }
          sx={ {
            width: 280,
            marginX: isPc ? 10 : "auto",
            marginTop: isPc ? 10 : 2,
            marginBottom: isPc ? 3 : 2,
            border: "none",
            background: "#fff",
            alignItems: "center",
            textAlign: "center",
          } }
          inputProps={ {
            style: {
              background: "#fff",
              color: "#000",
            }
          } }
          size={ "small" }
          defaultValue={ targetYearMonth }
          onChange={ async (event) => {
            event.preventDefault()
            setTargetYearMonth(event.target.value)
            setTrainingImages([])
            await getTrainings(event.target.value)
          } }
        >
          { yearMonthItems.map((yearMonth) => {
            return (
              <MenuItem value={ yearMonth } sx={ { padding: 1, textAlign: "center" } }>{ yearMonth }</MenuItem>
            )
          }) }
        </Select>
      </Box>
      <Box sx={ {
        background: "#fff",
        color: "#000",
        width: "100%",
        paddingTop: isPc ? 5 : 0,
        paddingBottom: 3,
      } }>
        <ImageList sx={ { width: isPc ? "90%" : "90%", marginX: "auto", paddingY: 2, justifyContent: "center" } }
                   cols={ isPc ? 4 : 3 }
                   gap={ isPc ? 10 : 5 }>
          { trainingImages === undefined ? "" : trainingImages.map((training) => {
            return (
              <ImageListItem key={ training.trainingId }>
                <Link to={ `/training/${ training.trainingId }` } style={ {
                  textDecoration: "none",
                  textAlign: isPc ? "right" : "left",
                  color: "#5B5B5B"
                } }>
                  <img src={ training.thumbnailUrl } width={ isPc ? "100%" : "100%" }
                    // height={ isPc ? 340 : 180 }
                       style={ { objectFit: "cover", minHeight: isPc ? 320 : 180,pointerEvents: "none" } }/>
                  <ImageListItemBar
                    title={ dayjs(training.trainingDate).format(DateFormat.YYYY_MM_DD) }
                    position={ "below" }
                    sx={ {
                      fontSize: isPc ? 16 : 10,
                      paddingY: 0,
                      marginY: 0,
                    } }
                  />
                </Link>
              </ImageListItem>
            )
          }) }
        </ImageList>
      </Box>
      { isPc ? (
        <Box
          sx={ {
            width: "100%",
            backgroundImage: `url(${ loginBg2 })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            objectFit: "cover",
            paddingY: 10,
            display: "flex",
            justifyContent: "center",
            textAlign: "center"
          } }
          onContextMenu={ () => {
            return false
          } }
        >
          <Link to={ "/measurement" } style={ {
            color: "#fff",
            textDecoration: "none",
            background: "#A39670",
            padding: 10,
            width: 360,
            height: 240,
            boxSizing: "border-box",
            borderRadius: 10,
            marginLeft: 10,
            fontWeight: "bold"
          } }>
            <Box
              sx={ {
                marginTop: isPc ? 5 : 1,
                alignItems: "center",
                marginBottom: 1,
              } }
            >
              { (<img src={ measurementIcon1 } width={ 100 }/>) }
            </Box>
            部位別測定を見る
          </Link>
        </Box>
      ) : (
        <Box sx={ {
          width: "90%",
          marginX: "auto",
          marginY: 3,
          display: "flex",
          textAlign: "center",
          justifyContent: "center"
        } }>
          <Link to={ "/measurement" } style={ {
            color: "#A39760",
            textDecoration: "none",
            background: "#353535",
            padding: 10,
            width: 180,
            height: 100,
            boxSizing: "border-box",
            marginLeft: 10,
            fontWeight: "bold"
          } }>
            <Box
              sx={ {
                marginTop: 1,
                alignItems: "center",
                marginBottom: 1,
              } }
            >
              { (
                <img src={ measurementIcon2 } width={ 50 }/>) }
            </Box>
            部位別測定を見る
          </Link>
        </Box>
      ) }
      { !isPc && (
        <Box
          sx={ {
            width: "100%",
            backgroundImage: `url(${ loginBg2 })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            objectFit: "cover",
            paddingY: 10,
            display: "flex",
            justifyContent: "center",
            textAlign: "center"
          } }
        >
        </Box>
      ) }
    </StyledDiv>
  )
}