import { StyledDiv } from "../Home";
import { Box, ImageList, ImageListItem, ImageListItemBar, useMediaQuery } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchTrainingDetail, fetchTrainingImage } from "../../service/api";
import { TrainingDetail } from "../../domain/Training";
import loginBg2 from "../../assets/login_BG2.jpg";
import noImage from "../../assets/no-image.png"
import { KeyboardDoubleArrowRight } from "@mui/icons-material";
import measurementIcon1 from "../../assets/measurement_icon1.png";
import measurementIcon2 from "../../assets/measurement_icon2.png";
import dayjs from "dayjs";
import { DateFormat } from "../../domain/DateFormat";
import { Loading } from "../../component/common/Loading";
import { persister } from "../../index";

interface MainImg {
  img: string,
  viewName: string
}

export const Detail = () => {
  const isPc = useMediaQuery("(min-width:768px)")
  const params = useParams<{ trainingId: string }>()
  const navigate = useNavigate()
  const [ mainImg, setMainImg ] = useState<MainImg>()
  const [ trainingDetail, setTrainingDetail ] = useState<TrainingDetail>()
  const [ targetYM, setTargetYM ] = useState<string>()
  const [ loading, setLoading ] = useState(false)
  useEffect(() => {
    const getDetail = async () => {
      setLoading(true)
      try {
        if (typeof params.trainingId === 'undefined') return
        await fetchTrainingDetail(params.trainingId).then(async (res) => {
          const frontImage = await fetchTrainingImage(res.frontImageUrl)
          const sideLImage = await fetchTrainingImage(res.sideLImageUrl)
          const sideRImage = await fetchTrainingImage(res.sideRImageUrl)
          const backImage = await fetchTrainingImage(res.backImageUrl)
          setTrainingDetail({
            trainingId: res.trainingId,
            trainingDate: res.trainingDate,
            frontImageUrl: frontImage,
            sideLImageUrl: sideLImage,
            sideRImageUrl: sideRImage,
            backImageUrl: backImage
          })
          if (frontImage !== noImage) setMainImg({ img: frontImage, viewName: "FRONT" })
          else if (sideLImage !== noImage) setMainImg({ img: sideLImage, viewName: "LEFT" })
          else if (sideRImage !== noImage) setMainImg({ img: sideRImage, viewName: "RIGHT" })
          else if (backImage !== noImage) setMainImg({ img: backImage, viewName: "BACK" })
          else setMainImg({ img: noImage, viewName: "FRONT" })
          const targetYM = dayjs(res.trainingDate).format(DateFormat.YY_MM)
          setTargetYM(targetYM)
        })
      } catch (e: any) {
        if (e.response && e.response.status === 401) {
          alert("セッション切れのため、ログアウトします。")
          persister.purge()
          navigate("/login")
          return
        }
        console.log(e)
        alert(e)
      } finally {
        setLoading(false)
      }
    }
    getDetail()
  }, [ params.trainingId ])
  if (loading) {
    return (<Loading/>)
  }
  return (
    <StyledDiv onContextMenu={ (e) => {
      e.preventDefault()
      return false
    } }>
      <Box width={ "100%" } textAlign={ "center" } bgcolor={ "#464646" } height={ isPc ? 100 : 10 }/>
      <Box
        sx={ {
          background: "#fff",
          justifyContent: "center",
          textAlign: "center",
          paddingTop: isPc ? 10 : 2,
          paddingBottom: isPc ? 8 : 2,
        } }
      >
        <Box sx={ {
          width: "90%",
          marginX: "auto",
          position: "relative"
        } }>
          <img src={ mainImg?.img } width={ isPc ? 400 : 250 }/>
          { !isPc && (
            <Box sx={ {
              position: "absolute",
              top: 2,
              left: "15%",
              background: "#A39760",
              color: "#fff",
              width: 70,
              justifyContent: "center",
              fontSize: 14
            } }>
              { mainImg?.viewName }
            </Box>
          ) }
        </Box>
        <Box
          sx={ {
            width: "75%",
            marginX: "auto",
            textAlign: "center"
          } }
        >
          <ImageList cols={ 4 } gap={ 10 }>
            <ImageListItem key={ trainingDetail?.frontImageUrl } onClick={ () => {
              if (typeof trainingDetail?.frontImageUrl === "undefined") return
              if (trainingDetail?.frontImageUrl === noImage) return;
              setMainImg({ img: trainingDetail?.frontImageUrl, viewName: "FRONT" })
            } }>
              <img src={ trainingDetail?.frontImageUrl }
                   style={ { opacity: mainImg?.viewName === "FRONT" ? 1 : 0.5 } }/>
              { isPc && (
                <ImageListItemBar
                  position={ "below" }
                  sx={ {
                    background: "#A39760",
                    width: "80%",
                    textAlign: "center",
                    marginY: 2,
                    marginX: "auto",
                    color: "#fff",
                  } }
                  title={ "FRONT" }
                >
                </ImageListItemBar>
              ) }
            </ImageListItem>
            <ImageListItem key={ trainingDetail?.sideLImageUrl } onClick={ () => {
              if (typeof trainingDetail?.sideLImageUrl === "undefined") return
              if (trainingDetail?.sideLImageUrl === noImage) return
              setMainImg({ img: trainingDetail?.sideLImageUrl, viewName: "LEFT" })
            } }>
              <img src={ trainingDetail?.sideLImageUrl }
                   style={ { opacity: mainImg?.viewName === "LEFT" ? 1 : 0.5 } }/>
              { isPc && (
                <ImageListItemBar
                  position={ "below" }
                  sx={ {
                    background: "#A39760",
                    width: "80%",
                    textAlign: "center",
                    marginY: 2,
                    marginX: "auto",
                    color: "#fff",
                  } }
                  title={ "LEFT" }
                >
                </ImageListItemBar>
              ) }
            </ImageListItem>
            <ImageListItem key={ trainingDetail?.sideRImageUrl } onClick={ () => {
              if (typeof trainingDetail?.sideRImageUrl === "undefined") return
              if (trainingDetail?.sideRImageUrl === noImage) return
              setMainImg({ img: trainingDetail?.sideRImageUrl, viewName: "RIGHT" })
            } }>
              <img src={ trainingDetail?.sideRImageUrl }
                   style={ { opacity: mainImg?.viewName === "RIGHT" ? 1 : 0.5 } }/>
              { isPc && (
                <ImageListItemBar
                  position={ "below" }
                  sx={ {
                    background: "#A39760",
                    width: "80%",
                    textAlign: "center",
                    marginY: 2,
                    marginX: "auto",
                    color: "#fff",
                  } }
                  title={ "RIGHT" }
                >
                </ImageListItemBar>
              ) }
            </ImageListItem>
            <ImageListItem key={ trainingDetail?.backImageUrl } onClick={ () => {
              if (typeof trainingDetail?.backImageUrl === "undefined") return
              if (trainingDetail?.backImageUrl === noImage) return
              setMainImg({ img: trainingDetail?.backImageUrl, viewName: "BACK" })
            } }>
              <img src={ trainingDetail?.backImageUrl } style={ { opacity: mainImg?.viewName === "BACK" ? 1 : 0.5 } }/>
              { isPc && (
                <ImageListItemBar
                  position={ "below" }
                  sx={ {
                    background: "#A39760",
                    width: "80%",
                    textAlign: "center",
                    marginY: 2,
                    marginX: "auto",
                    color: "#fff",
                  } }
                  title={ "BACK" }
                >
                </ImageListItemBar>
              ) }
            </ImageListItem>
          </ImageList>
          <Link to={ "/training?targetYM=" + targetYM }
                style={ {
                  alignItems: "center",
                  textDecoration: "none",
                  color: "#A39760",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 40,
                } }>
            <KeyboardDoubleArrowRight sx={ { color: "#A39760", display: "block" } } fontSize={ "small" }/>
            <span>BACK</span>
          </Link>
        </Box>
      </Box>
      { isPc ? (
        <Box
          sx={ {
            width: "100%",
            backgroundImage: `url(${ loginBg2 })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            objectFit: "cover",
            paddingY: 10,
            display: "flex",
            justifyContent: "center",
            textAlign: "center"
          } }
        >
          <Link to={ "/measurement" } style={ {
            color: isPc ? "#fff" : "#A39760",
            textDecoration: "none",
            background: "rgb(255,255,255,0.3)",
            padding: 10,
            width: isPc ? 360 : 180,
            height: isPc ? 240 : 100,
            boxSizing: "border-box",
            borderRadius: 10,
            marginLeft: 10,
            fontWeight: "bold",
            alignItems: "center",
          } }>
            <Box
              sx={ {
                marginTop: isPc ? 5 : 1,
                alignItems: "center",
                marginBottom: 1,
              } }
            >
              { isPc ? (<img src={ measurementIcon1 } width={ 100 }/>) : (
                <img src={ measurementIcon2 } width={ 50 }/>) }
            </Box>
            部位別測定を見る
          </Link>
        </Box>
      ) : (
        <Box sx={ {
          width: "90%",
          marginX: "auto",
          marginY: 3,
          display: "flex",
          textAlign: "center",
          justifyContent: "center"
        } }>
          <Link to={ "/measurement" } style={ {
            color: "#A39760",
            textDecoration: "none",
            background: isPc ? "#A39670" : "#353535",
            padding: 10,
            width: isPc ? 250 : 180,
            height: isPc ? 200 : 100,
            boxSizing: "border-box",
            marginLeft: 10,
            fontWeight: "bold"
          } }>
            <Box
              sx={ {
                marginTop: isPc ? 3 : 1,
                alignItems: "center",
                marginBottom: 1,
              } }
            >
              { isPc ? (<img src={ measurementIcon1 } width={ 100 }/>) : (
                <img src={ measurementIcon2 } width={ 50 }/>) }
            </Box>
            部位別測定を見る
          </Link>
        </Box>
      ) }
      { !isPc && (
        <Box
          sx={ {
            width: "100%",
            backgroundImage: `url(${ loginBg2 })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            objectFit: "cover",
            paddingY: 10,
            display: "flex",
            justifyContent: "center",
            textAlign: "center"
          } }
        >
        </Box>
      ) }
    </StyledDiv>
  )
}