import { useState } from "react";
import { Box, Button, Drawer, Icon, IconButton, Link, useMediaQuery } from "@mui/material";
import { Close, Menu } from "@mui/icons-material";
import styled from "styled-components";
import { ReservationButton } from "./ReservationButton";
import { LogoutButton } from "./LogoutButton";
import { OtherLinkItem } from "./OtherLinkItem";
import { HomeButton } from "./HomeButton";
import logo1 from "../../assets/logo_1.png"

const Header = styled.header`
  background: #353535;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  padding-top: 2%;
  padding-bottom: 2%;
  margin-bottom: 30px;
  justify-content: end;
  align-items: center;
  height: 60px;
  display: flex;
  font-family: "Arvo";
  @media screen and (min-width: 768px) {
    justify-content: start;
  }

`

const Hamburger = styled.div`
  position: absolute;
  left: 0;
  top: 0;
`
const HeaderItem = styled.div`
  margin-left: 20px;
  margin-right: 10px;
  margin-top: 10px;
`

type Props = {
  isLoggedIn: boolean
}
const AppHeader = ({ isLoggedIn }: Props) => {
  const [ drawerOpened, setDrawerOpened ] = useState(false)
  const isPC = useMediaQuery("(min-width:768px)")
  return (
    <Header>
      { isPC ? (
        <Link href={ "/" } sx={ {
          width: "60%",
          textAlign: "left",
          marginLeft: 1
        } }>
          <img src={ logo1 } style={ { objectFit: "cover" } } width={ 200 }/>
        </Link>

      ) : (
        <Hamburger>
          <IconButton
            onClick={ () => setDrawerOpened(true) }
            size={ "large" }
            sx={ {
              marginRight: 1,
              marginLeft: 0,
              background: "#464646",
              borderRadius: 0,
              height: 60,
              width: 60,
              marginY: 0,
              visibility: drawerOpened ? "hidden" : "visible"
            } }
            aria-label={ "menu" }
          >
            <Menu
              color={ "action" }
              sx={ { color: "#fff", width: "100%", height: "100%" } }
            />
          </IconButton>
          {/*<img*/ }
          {/*  src={}*/ }
          {/*  onClick={() => setDrawerOpened(true)}/>*/ }
          <Drawer
            anchor={ "left" }
            open={ drawerOpened }
            sx={ {
              opacity: 0.9,
              position: "fixed",
              zIndex: 120,
              top: 0,
              left: 0,
              width: "60%",
              height: "80vh",
              color: "#000",
              transition: "all 0.6s",
            } }
            onClose={ () => setDrawerOpened(false) }
          >
            <IconButton
              sx={ {
                width: "100%",
                textAlign: "left",
                justifyContent: "left",
                color: "#000"
              } }
            >
              <Close
                onClick={ () => setDrawerOpened(false) }
                fontSize={ "large" }
              />
            </IconButton>
            { isLoggedIn && (
              <ReservationButton marginX={ 2 }/>
            ) }
            { isLoggedIn && (
              <LogoutButton marginLeft={ 2 } marginRight={ 2 }/>
            ) }
            { isLoggedIn && (<HeaderItem>
              <Link href={ "/" } sx={ { textDecoration: "none" } } color={ "#000" }>HOME</Link>
            </HeaderItem>) }
            { isLoggedIn && (<HeaderItem>
              <Link href={ "/training" } sx={ { textDecoration: "none" } } color={ "#000" }>過去のボデイを見る</Link>
            </HeaderItem>) }
            { isLoggedIn && (<HeaderItem>
              <Link href={ "/measurement" } sx={ { textDecoration: "none" } } color={ "#000" }>部位別測定を見る</Link>
            </HeaderItem>) }
            <hr style={ { width: "80%", margin: "10px auto", border: "0.5px solid #ccc" } }/>
            <HeaderItem>
              <OtherLinkItem url={ "#" } name={ "マッスルフォトとは" }/>
            </HeaderItem>
            <HeaderItem>
              <OtherLinkItem url={ "#" } name={ "運営会社" }/>
            </HeaderItem>
            <HeaderItem>
              <OtherLinkItem url={ "#" } name={ "お問い合わせ" }/>
            </HeaderItem>
            <HeaderItem>
              <OtherLinkItem url={ "#" } name={ "サイトのご利用について" }/>
            </HeaderItem>
            <HeaderItem>
              <OtherLinkItem url={ "#" } name={ "プライバシーポリシー" }/>
            </HeaderItem>
            <HeaderItem>
              <OtherLinkItem url={ "#" } name={ "個人情報保護方針" }/>
            </HeaderItem>
          </Drawer>
        </Hamburger>
      ) }
      { isLoggedIn && !isPC && (
        <HomeButton/>
      ) }

      { isLoggedIn && (
        <Box sx={ {
          display: "flex",
          justifyContent: "end",
          width: isPC ? "40%" : 250
        } }>
          <ReservationButton marginX={ 2 }/>
          <LogoutButton marginLeft={ 0 } marginRight={ 2 }/>
        </Box>
      ) }

    </Header>
  )
}
export default AppHeader