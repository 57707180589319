import { Button, useMediaQuery } from "@mui/material";
import { fetchLogout } from "../../service/api";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsLoggedIn, setPasswordInit } from "../../features/auth/authSlice";
import { useState } from "react";
import { persister } from "../../index";
import loginIcon from "../../assets/login.svg"
import { Loading } from "./Loading";

type Props = {
  marginLeft: number,
  marginRight: number,
}

export const LogoutButton = ({ marginRight, marginLeft }: Props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isPC = useMediaQuery("(min-width:768px)")
  const [ isError, setIsError ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const logout = async () => {
    setLoading(true)
    try {
      const res = await fetchLogout().then((res) => {
        dispatch(setIsLoggedIn(false))
        dispatch(setPasswordInit(false))
        persister.purge()
        navigate('/login')
      })
    } catch (e: any) {
      setIsError(true)
      alert(e.message)
    } finally {
      setLoading(false)
    }
  }
  if (loading) {
    return (
      <Loading/>
    )
  }
  return (
    <Button
      onClick={ logout }
      sx={ {
        background: "#AFA295",
        marginRight: marginRight,
        marginLeft: marginLeft,
        paddingRight: 1,
        color: "#535353",
        marginY: 1,
        fontSize: isPC ? 16 : 12,
        // width: "90%"
      } }
    >
      <img src={ loginIcon } width={ 15 } style={ { marginRight: 5 } }/>ログアウト
    </Button>
  )
}