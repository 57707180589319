import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AuthState, getLoggedIn, setIsLoggedIn } from "../features/auth/authSlice";
import { useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import loginBG from "../assets/login_BG.jpg"
import topSp from "../assets/top_sp.jpg"
import bodyIcon1 from "../assets/body_icon1.png"
import bodyIcon2 from "../assets/body_icon2.png"
import measurementIcon1 from "../assets/measurement_icon1.png"
import measurementIcon2 from "../assets/measurement_icon2.png"
import mpIcon from "../assets/mp-icon.png"
import loginBg2 from "../assets/login_BG2.jpg"
import appleIcon from "../assets/apple-icon-180x180.png"
import logo3 from "../assets/logo_3.png"
import logo2 from "../assets/logo_2.png"
import { fetchGetUser, fetchLogout } from "../service/api";
import styled from "styled-components";
import { persister } from "../index";

export const StyledDiv = styled.div`
  background: #353535;
`

const Home = () => {
  const isPc = useMediaQuery("(min-width:768px)")
  const isLoggedIn = useSelector(getLoggedIn)
  const navigate = useNavigate()
  if (!isLoggedIn) {
    navigate("/login")
  }
  const [ userName, setUserName ] = useState("")
  useEffect(() => {
    async function getUser() {
      try {
        const res = await fetchGetUser()
        if (!res.data.isActive) {
          const logoutRes = await fetchLogout()
          if (logoutRes.result) {
            navigate("/login")
          }
        }
        setUserName(res.data.lastName)
        return res
      } catch (e: any) {
        if (e.response && e.response.status === 401) {
          alert("セッション切れのため、ログアウトします。")
          persister.purge()
          navigate("/login")
          return
        }
        alert(e)
        console.log(e)
      }
    }

    getUser()
  }, [])
  return (
    <StyledDiv>
      <Box
        sx={ {
          backgroundImage: `url(${ loginBG })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          textAlign: "center",
          paddingY: 1,
          color: "#000"
        } }
      >
        <Box
          sx={ isPc ? {
            width: 500,
            background: "#fff",
            marginX: "auto",
            marginY: 3
          } : {
            width: "90%",
            background: "#fff",
            marginX: "auto",
            marginY: 1,
          } }
        >
          <Box sx={ {
            color: "#000",
            paddingTop: 3,
            paddingBottom: 2,
            width: "90%",
            marginX: "auto",
            textAlign: "center",
            alignItems: "center",
          } }>
            <img src={ mpIcon } width={ 20 } style={ { marginRight: 10 } }/>
            <img src={ logo3 } width={ 200 } style={ { color: "#000", paddingBottom: 1, marginBottom: 2, } }/>
          </Box>
          <Box
            sx={ {
              display: "flex",
              width: "90%",
              objectFit: "contain",
              marginX: "auto",
              paddingTop: 1,
              paddingBottom: 2,
              alignItems: "center",
              justifyContent: "center"
            } }
          >
            { isPc ? (
              <img src={ topSp } width={ 250 } height={ 200 }/>
            ) : (
              <img src={ topSp } width={ 150 } height={ 120 }/>
            ) }

            <p style={ isPc ? { marginLeft: 20 } : { marginLeft: 10, fontSize: 14 } }>こんにちは{ userName }様</p>
          </Box>
        </Box>
      </Box>
      <Box sx={ {
        width: "90%",
        marginX: "auto",
        marginY: 3,
        display: "flex",
        textAlign: "center",
        justifyContent: "center"
      } }>
        <Link to={ "/training" } style={ {
          color: isPc ? "#fff" : "#A39760",
          textDecoration: "none",
          background: isPc ? "#A39670" : "#353535",
          padding: 10,
          width: isPc ? 250 : 190,
          height: isPc ? 200 : 100,
          display: "block",
          marginRight: 10,
          fontSize: isPc ? 16 : 14,
          fontWeight: "bold"
        } }>
          <Box sx={ {
            alignItems: "center",
            marginTop: isPc ? 3 : 1,
            marginBottom: 1,
          } }>
            { isPc ? (<img src={ bodyIcon1 } width={ 100 }/>) : (<img src={ bodyIcon2 } width={ 50 }/>) }
          </Box>
          過去のボディを見る
        </Link>
        <Link to={ "/measurement" } style={ {
          color: isPc ? "#fff" : "#A39760",
          textDecoration: "none",
          background: isPc ? "#A39670" : "#353535",
          padding: 10,
          width: isPc ? 250 : 180,
          height: isPc ? 200 : 100,
          boxSizing: "border-box",
          marginLeft: 10,
          fontSize: isPc ? 16 : 14,
          fontWeight: "bold"
        } }>
          <Box
            sx={ {
              marginTop: isPc ? 3 : 1,
              alignItems: "center",
              marginBottom: 1,
            } }
          >
            { isPc ? (<img src={ measurementIcon1 } width={ 100 }/>) : (<img src={ measurementIcon2 } width={ 50 }/>) }
          </Box>
          部位別測定を見る
        </Link>
      </Box>
      { isPc && (<Box sx={ {
        textAlign: "center",
        marginBottom: 3
      } }>
        <img src={ logo2 } width={ 700 } style={ { objectFit: "cover" } }/>
      </Box>) }
      <Box
        sx={ {
          width: "100%",
          backgroundImage: `url(${ loginBg2 })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          objectFit: "cover",
          paddingY: 10
        } }
      >
      </Box>
    </StyledDiv>

  )
}

export default Home