import { Box, Button, Icon, useMediaQuery } from "@mui/material";
import styled from "styled-components";
import { Image } from "@mui/icons-material";
import { Link } from "react-router-dom";
import logo3 from "../../assets/logo_3.png"
import { ReservationButton } from "./ReservationButton";
import { OtherLinkItem } from "./OtherLinkItem";

const Footer = styled.footer`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  text-align: center;
  color: #fff;
`
const StyledLink = styled(Link)`
  color: #fff;

  &:hover {
    color: #191970;
  }
`
const AppFooter = () => {
  const isPc = useMediaQuery("(min-width:768px)")
  return (
    <Footer>
      <Box sx={ { width: "100%" } }>
        <Box>
          <Box sx={ {
            background: "#333",
            paddingTop: 10,
          } }>
            <img src={ logo3 } width={ isPc ? 300 : 250 }/>
          </Box>
          <Box
            sx={ {
              border: "1px solid",
              color: "#fff",
              fontWeight: "bold",
              marginX: "auto",
              paddingRight: 1,
              marginY: 5,
              fontSize: 18,
              width: 150,
              ":hover": {
                color: "#191970"
              },
            } }
          >
            <StyledLink to={ "https://muscle-photo.com/" } target={ "_blank" } style={ {
              textDecoration: "none",
            } }>CONTACT</StyledLink>
          </Box>
        </Box>
      </Box>
      <Box component={ "div" } sx={ {
        width: isPc ? "60%" : "90%",
        marginX: "auto"
      } }>
        <Box
          sx={ {
            display: "block",
            justifyContent: "center"
          } }
        >
          <Link to={ "#" } target={ "_blank" } style={ {
            fontSize: 14,
            color: "#fff",
            textDecoration: "none",
            display: "inline-block",
            padding: 3,
            marginLeft: isPc ? 15 : 5,
            marginRight: isPc ? 15 : 5,
          } }>運営方針</Link>
          <Link to={ "#" } target={ "_blank" } style={ {
            fontSize: 14,
            color: "#fff",
            textDecoration: "none",
            display: "inline-block",
            padding: 3,
            marginLeft: isPc ? 15 : 5,
            marginRight: isPc ? 15 : 5,
          } }>サイトのご利用について</Link>
          <Link to={ "#" } target={ "_blank" } style={ {
            fontSize: 14,
            color: "#fff",
            textDecoration: "none",
            display: "inline-block",
            padding: 3,
            marginLeft: isPc ? 15 : 5,
            marginRight: isPc ? 15 : 5,
          } }>プライバシーポリシー</Link>
          <Link to={ "#" } target={ "_blank" } style={ {
            fontSize: 14,
            color: "#fff",
            textDecoration: "none",
            display: "inline-block",
            padding: 3,
            marginLeft: isPc ? 15 : 5,
            marginRight: isPc ? 15 : 5,
          } }>個人情報方針</Link>
        </Box>
        <Box
          sx={ {
            display: "flex",
            justifyContent: "center"
          } }
        >
        </Box>
      </Box>
      <Box sx={ {
        marginY: 3,
        fontSize: 14,
      } }>
        COPYRIGHT(C)MUSCLE PHOTO
      </Box>
    </Footer>
  )
}
export default AppFooter