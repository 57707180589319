import styled from "styled-components";
import AppFooter from "../../component/common/AppFooter";
import {
  Alert,
  Box,
  Button,
  FormHelperText,
  OutlinedInput,
  Snackbar,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
import loginBg from "../../assets/login_BG2.jpg";
import { SubmitHandler, useForm } from "react-hook-form";
import { KeyboardArrowRight } from "@mui/icons-material";
import { fetchInitPassword } from "../../service/api";
import { useNavigate } from "react-router-dom";
import { ValidationMessage } from "../../domain/ValidationMessage";
import { Sentence } from "../../component/common/Sentence";
import { useState } from "react";
import { persister } from "../../index";
import { Loading } from "../../component/common/Loading";

const Main = styled.main`
  color: #000;
`

interface PasswordInitForm {
  password: string,
  confirmPassword: string
}

const PasswordInit = () => {
  const isPC = useMediaQuery("(min-width:768px)")
  const navigate = useNavigate()
  const [ isSend, setIsSend ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ isError, setIsError ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState("")
  const {
    register,
    formState: { errors },
    getValues,
    trigger,
    handleSubmit
  } = useForm<PasswordInitForm>({
    mode: "onBlur",
    criteriaMode: "all"
  })

  const onSubmit: SubmitHandler<PasswordInitForm> = async (data: PasswordInitForm) => {
    setLoading(true)
    try {
      const res = await fetchInitPassword(data.password)
      if (res.result) {
        await setIsSend(true)
        navigate('/')
        return
      }
      setIsError(true)
      setErrorMessage(res.message)
    } catch (e: any) {
      if (e.response && e.response.status === "401") {
        alert("セッション切れのため、ログアウトします。")
        persister.purge()
        navigate("/login")
        return
      }
      setIsError(true)
      setErrorMessage(e.message)
    } finally {
      setLoading(false)
    }
  }
  if (loading) {
    return (<Loading/>)
  }
  return (
    <Main>
      <Box sx={ {
        backgroundImage: `url(${ loginBg })`,
        paddingY: 2,
        backgroundSize: "cover",
        backgroundPositionY: "center"
      } }>
        <Typography component={ "h2" } variant={ "h4" } sx={ {
          marginY: 2,
          textAlign: "center",
          fontSize: { xs: 20, md: 32 },
          fontWeight: "bold",
          color: "#fff"

        } }>初期パスワード設定</Typography>
      </Box>
      <Box sx={ {
        marginX: "auto",
        width: "90%",
        // width: {xs:"90%", sm: "80%", lg: "50%"},
        textAlign: "center",
        paddingY: 3,
        background: "#EDEDED"
      } }>
        <Box sx={ { width: "90%", marginX: "auto" } }>
          <Sentence>初期パスワードをご入力ください。</Sentence>
        </Box>
        { !isSend ? (<Stack
          component={ "form" }
          noValidate
          onSubmit={ handleSubmit(onSubmit) }
          sx={ { width: { xs: "90%", sm: "85%", md: "70%", lg: "50%" }, marginX: "auto", marginY: 2 } }
          visibility={ isSend ? "hidden" : "visible" }
        >
          <Box marginY={ 1 } width={ "100%" } textAlign={ isPC ? "right" : "left" } sx={ { fontSize: 12 } }>
            <label>パスワード入力</label>
            <OutlinedInput
              { ...register("password", {
                required: ValidationMessage.required,
                minLength: { value: 8, message: ValidationMessage.min },
                onBlur: () => {
                  if (getValues("confirmPassword")) {
                    trigger("confirmPassword")
                  }
                }
              }) }
              type={ "password" }
              fullWidth={ !isPC }
              error={ "password" in errors }
              label={ "パスワード" }
              placeholder={ "パスワードを入力してください" }
              sx={ isPC ? {
                  width: 400, background: "#D3D7DE", marginX: 2, fontSize: 16, border: "none", color: "#535353"
                }
                : {
                  background: "#D3D7DE", fontSize: 14, color: "#535353"
                } }
              size={ "small" }
            />
            <FormHelperText sx={ { color: "red", textAlign: "center" } }>{ errors.password?.message }</FormHelperText>
          </Box>
          <Box marginY={ 1 } width={ "100%" } textAlign={ isPC ? "right" : "left" } sx={ { fontSize: 12 } }>
            <label>パスワード入力（確認）</label>
            <OutlinedInput
              { ...register("confirmPassword", {
                required: ValidationMessage.required,
                minLength: { value: 8, message: ValidationMessage.min },
                validate: (value) => {
                  return (
                    value === getValues("password") || ValidationMessage.passwordNotEqual
                  )
                }
              }) }
              type={ "password" }
              fullWidth={ !isPC }
              error={ "confirmPassword" in errors }
              label={ "パスワード（確認）" }
              placeholder={ "パスワードを入力してください" }
              sx={ isPC ? {
                  width: 400, background: "#D3D7DE", marginX: 2, fontSize: 16, border: "none", color: "#535353"
                }
                : {
                  background: "#D3D7DE", fontSize: 14, color: "#535353"
                } }
              size={ "small" }
            />
            <FormHelperText
              sx={ { color: "red", textAlign: "center" } }>{ errors.confirmPassword?.message }</FormHelperText>
          </Box>
          <Button variant={ "contained" } type={ "submit" } sx={ {
            background: "#353535",
            color: '#A39760',
            width: 200,
            marginX: "auto",
            paddingY: 1,
            fontSize: 20,
            marginTop: 3,
            fontFamily: "Arvo",
            fontWeight: "bold",
            ":hover": {
              background: "#353535",
              opacity: 0.9
            }
          } }
          >
            SEND <KeyboardArrowRight sx={ { fontSize: 20, fontWeight: 100 } }/>
          </Button>
          <Snackbar
            open={ isError }
            autoHideDuration={ 6000 }
            onClose={ () => setIsError(false) }
          >
            <Alert
              severity={ "error" }
              onClose={ () => setIsError(false) }
              sx={ { width: '100%' } }
            >{ errorMessage }</Alert>
          </Snackbar>
        </Stack>) : (
          <Typography visibility={ isSend ? "visible" : "hidden" }>パスワード再設定が完了しました。</Typography>) }
      </Box>
    </Main>
  )
}

export default PasswordInit